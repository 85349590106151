export const countryList = [
    { "label": "Russia", "value": "RU" },
    { "label": "Denmark", "value": "DK" },
    { "label": "El Salvador", "value": "SV" },
    { "label": "Senegal", "value": "SN" },
    { "label": "Slovenia", "value": "SI" },
    { "label": "Czech Republic", "value": "CZ" },
    { "label": "South Korea", "value": "KR" },
    { "label": "Japan", "value": "JP" },
    { "label": "Bahamas", "value": "BS" },
    { "label": "Venezuela", "value": "VE" },
    { "label": "Uzbekistan", "value": "UZ" },
    { "label": "Australia", "value": "AU" },
    { "label": "Chile", "value": "CL" },
    { "label": "Qatar", "value": "QA" },
    { "label": "Mozambique", "value": "MZ" },
    { "label": "Bermuda", "value": "BM" },
    { "label": "Estonia", "value": "EE" },
    { "label": "Georgia", "value": "GE" },
    { "label": "Austria", "value": "AT" },
    { "label": "Chad", "value": "TD" },
    { "label": "Liechtenstein", "value": "LI" },
    { "label": "Ivory Coast", "value": "CI" },
    { "label": "Vietnam", "value": "VN" },
    { "label": "Kuwait", "value": "KW" },
    { "label": "Argentina", "value": "AR" },
    { "label": "Brazil", "value": "BR" },
    { "label": "Namibia", "value": "NA" },
    { "label": "Rwanda", "value": "RW" },
    { "label": "Poland", "value": "PL" },
    { "label": "Liberia", "value": "LR" },
    { "label": "Mayotte", "value": "YT" },
    { "label": "Laos", "value": "LA" },
    { "label": "Monaco", "value": "MC" },
    { "label": "Malta", "value": "MT" },
    { "label": "Belarus", "value": "BY" },
    { "label": "Gibraltar", "value": "GI" },
    { "label": "Egypt", "value": "EG" },
    { "label": "Sri Lanka", "value": "LK" },
    { "label": "Saudi Arabia", "value": "SA" },
    { "label": "San Marino", "value": "SM" },
    { "label": "Germany", "value": "DE" },
    { "label": "Trinidad and Tobago", "value": "TT" },
    { "label": "Slovakia", "value": "SK" },
    { "label": "South Sudan", "value": "SS" },
    { "label": "Lithuania", "value": "LT" },
    { "label": "Jamaica", "value": "JM" },
    { "label": "Mauritius", "value": "MU" },
    { "label": "Cyprus", "value": "CY" },
    { "label": "French Polynesia", "value": "PF" },
    { "label": "Barbados", "value": "BB" },
    { "label": "Antigua and Barbuda", "value": "AG" },
    { "label": "Hungary", "value": "HU" },
    { "label": "Finland", "value": "FI" },
    { "label": "Serbia", "value": "RS" },
    { "label": "Ecuador", "value": "EC" },
    { "label": "Angola", "value": "AO" },
    { "label": "Kyrgyzstan", "value": "KG" },
    { "label": "Togo", "value": "TG" },
    { "label": "Ireland", "value": "IE" },
    { "label": "Jersey", "value": "JE" },
    { "label": "Réunion", "value": "RE" },
    { "label": "Bonaire, Sint Eustatius and Saba", "value": "BQ" },
    { "label": "Bangladesh", "value": "BD" },
    { "label": "Ukraine", "value": "UA" },
    { "label": "Dominican Republic", "value": "DO" },
    { "label": "Spain", "value": "ES" },
    { "label": "Norway", "value": "NO" },
    { "label": "Bosnia and Herzegovina", "value": "BA" },
    { "label": "Thailand", "value": "TH" },
    { "label": "Tunisia", "value": "TN" },
    { "label": "Bahrain", "value": "BH" },
    { "label": "Palestinian territories", "value": "PS" },
    { "label": "Turkey", "value": "TR" },
    { "label": "Morocco", "value": "MA" },
    { "label": "Albania", "value": "AL" },
    { "label": "Colombia", "value": "CO" },
    { "label": "Belgium", "value": "BE" },
    { "label": "Algeria", "value": "DZ" },
    { "label": "Vatican City", "value": "VA" },
    { "label": "French Guiana", "value": "GF" },
    { "label": "Azerbaijan", "value": "AZ" },
    { "label": "Guatemala", "value": "GT" },
    { "label": "Myanmar", "value": "MM" },
    { "label": "Peru", "value": "PE" },
    { "label": "Paraguay", "value": "PY" },
    { "label": "Singapore", "value": "SG" },
    { "label": "United States", "value": "US" },
    { "label": "Kenya", "value": "KE" },
    { "label": "Yemen", "value": "YE" },
    { "label": "Uganda", "value": "UG" },
    { "label": "Libya", "value": "LY" },
    { "label": "Kazakhstan", "value": "KZ" },
    { "label": "New Zealand", "value": "NZ" },
    { "label": "Sierra Leone", "value": "SL" },
    { "label": "Honduras", "value": "HN" },
    { "label": "Uruguay", "value": "UY" },
    { "label": "United Arab Emirates", "value": "AE" },
    { "label": "Iraq", "value": "IQ" },
    { "label": "Netherlands", "value": "NL" },
    { "label": "India", "value": "IN" },
    { "label": "China", "value": "CN" },
    { "label": "Niger", "value": "NE" },
    { "label": "Brunei", "value": "BN" },
    { "label": "Montenegro", "value": "ME" },
    { "label": "Lesotho", "value": "LS" },
    { "label": "Zambia", "value": "ZM" },
    { "label": "Ethiopia", "value": "ET" },
    { "label": "Democratic Republic of the Congo", "value": "CD" },
    { "label": "Sudan", "value": "SD" },
    { "label": "Turks and Caicos Islands", "value": "TC" },
    { "label": "Malaysia", "value": "MY" },
    { "label": "Kosovo", "value": "XK" },
    { "label": "Hong Kong", "value": "HK" },
    { "label": "Maldives", "value": "MV" },
    { "label": "Nepal", "value": "NP" },
    { "label": "Martinique", "value": "MQ" },
    { "label": "Botswana", "value": "BW" },
    { "label": "Anguilla", "value": "AI" },
    { "label": "Sweden", "value": "SE" },
    { "label": "Ghana", "value": "GH" },
    { "label": "Saint Kitts and Nevis", "value": "KN" },
    { "label": "Burkina Faso", "value": "BF" },
    { "label": "Switzerland", "value": "CH" },
    { "label": "Seychelles", "value": "SC" },
    { "label": "Aruba", "value": "AW" },
    { "label": "Pakistan", "value": "PK" },
    { "label": "Bolivia", "value": "BO" },
    { "label": "Luxembourg", "value": "LU" },
    { "label": "Taiwan", "value": "TW" },
    { "label": "Canada", "value": "CA" },
    { "label": "Mali", "value": "ML" },
    { "label": "France", "value": "FR" },
    { "label": "Cayman Islands", "value": "KY" },
    { "label": "Fiji", "value": "FJ" },
    { "label": "Latvia", "value": "LV" },
    { "label": "Mexico", "value": "MX" },
    { "label": "Guernsey", "value": "GG" },
    { "label": "Romania", "value": "RO" },
    { "label": "Curaçao", "value": "CW" },
    { "label": "Tanzania", "value": "TZ" },
    { "label": "Somalia", "value": "SO" },
    { "label": "Macao", "value": "MO" },
    { "label": "Philippines", "value": "PH" },
    { "label": "Zimbabwe", "value": "ZW" },
    { "label": "New Caledonia", "value": "NC" },
    { "label": "Republic of the Congo", "value": "CG" },
    { "label": "Faroe Islands", "value": "FO" },
    { "label": "Gabon", "value": "GA" },
    { "label": "Isle of Man", "value": "IM" },
    { "label": "Indonesia", "value": "ID" },
    { "label": "Mongolia", "value": "MN" },
    { "label": "Oman", "value": "OM" },
    { "label": "Samoa", "value": "WS" },
    { "label": "Armenia", "value": "AM" },
    { "label": "Afghanistan", "value": "AF" },
    { "label": "Lebanon", "value": "LB" },
    { "label": "Panama", "value": "PA" },
    { "label": "Moldova", "value": "MD" },
    { "label": "Andorra", "value": "AD" },
    { "label": "South Africa", "value": "ZA" },
    { "label": "Portugal", "value": "PT" },
    { "label": "United States Minor Outlying Islands", "value": "UM" },
    { "label": "Nigeria", "value": "NG" },
    { "label": "Israel", "value": "IL" },
    { "label": "Jordan", "value": "JO" },
    { "label": "Bulgaria", "value": "BG" },
    { "label": "Italy", "value": "IT" },
    { "label": "United Kingdom", "value": "GB" },
    { "label": "Guadeloupe", "value": "GP" },
    { "label": "Iceland", "value": "IS" },
    { "label": "Costa Rica", "value": "CR" },
    { "label": "Croatia", "value": "HR" },
    { "label": "Cambodia", "value": "KH" },
    { "label": "North Macedonia", "value": "MK" },
    { "label": "Greece", "value": "GR" },
    { "label": "Benin", "value": "BJ" },
    { "label": "Cameroon", "value": "CM" },
    { "label": "Cape Verde", "value": "CV" },
]

export const appList = [
    { value: "VSK", label: "VSK" },
    { value: "VDK", label: "VDK" },
    { value: "VIA", label: "VIA" },
    { value: "VTK", label: "VTK" },
    { value: "ATA", label: "ATA" },
    { value: "URGENCY", label: "URGENCY" },
];

export const shopifyPlanList = [
    { "label": "plus_partner_sandbox", "value": "plus_partner_sandbox" },
    { "label": "frozen", "value": "frozen" },
    { "label": "open_learning", "value": "open_learning" },
    { "label": "custom", "value": "custom" },
    { "label": "shopify_plus", "value": "shopify_plus" },
    { "label": "affiliate", "value": "affiliate" },
    { "label": "starter_2022", "value": "starter_2022" },
    { "label": "comped", "value": "comped" },
    { "label": "staff_business", "value": "staff_business" },
    { "label": "npo_full", "value": "npo_full" },
    { "label": "partner_test", "value": "partner_test" },
    { "label": "trial", "value": "trial" },
    { "label": "professional", "value": "professional" },
    { "label": "sales_training", "value": "sales_training" },
    { "label": "staff", "value": "staff" },
    { "label": "fraudulent", "value": "fraudulent" },
    { "label": "closed", "value": "closed" },
    { "label": "cancelled", "value": "cancelled" },
    { "label": "npo_lite", "value": "npo_lite" },
    { "label": "business", "value": "business" },
    { "label": "shopify_alumni", "value": "shopify_alumni" },
    { "label": "basic", "value": "basic" },
    { "label": "paid_trial", "value": "paid_trial" },
    { "label": "unlimited", "value": "unlimited" },
    { "label": "dormant", "value": "dormant" }
]

export const categoryList = [
    { "label": "Media > Photography", "value": "Media > Photography" },
    { "label": "Religious & Ceremonial > Wedding Ceremony Supplies", "value": "Religious & Ceremonial > Wedding Ceremony Supplies" },
    { "label": "Office Supplies > Office Equipment", "value": "Office Supplies > Office Equipment" },
    { "label": "Apparel & Accessories > Nursing & Feeding", "value": "Apparel & Accessories > Nursing & Feeding" },
    { "label": "Health & Beauty", "value": "Health & Beauty" },
    { "label": "Furniture > Benches", "value": "Furniture > Benches" },
    { "label": "Apparel & Accessories > Hairdressing & Cosmetology", "value": "Apparel & Accessories > Hairdressing & Cosmetology" },
    { "label": "Apparel & Accessories > Handbags, Wallets & Cases", "value": "Apparel & Accessories > Handbags, Wallets & Cases" },
    { "label": "Apparel & Accessories > Accessories", "value": "Apparel & Accessories > Accessories" },
    { "label": "Hardware > Plumbing", "value": "Hardware > Plumbing" },
    { "label": "Vehicles & Parts > Vehicle Parts & Accessories", "value": "Vehicles & Parts > Vehicle Parts & Accessories" },
    { "label": "Business & Industrial > Dentistry", "value": "Business & Industrial > Dentistry" },
    { "label": "Business & Industrial > Janitorial Carts & Caddies", "value": "Business & Industrial > Janitorial Carts & Caddies" },
    { "label": "Electronics > Cell Phones & Accessories", "value": "Electronics > Cell Phones & Accessories" },
    { "label": "Home & Garden > Outdoor Furniture", "value": "Home & Garden > Outdoor Furniture" },
    { "label": "Luggage & Bags > Backpacks, Handbags, Wallets & Cases, Suitcases, Travel Bags", "value": "Luggage & Bags > Backpacks, Handbags, Wallets & Cases, Suitcases, Travel Bags" },
    { "label": "Baby & Toddler > Baby Safety", "value": "Baby & Toddler > Baby Safety" },
    { "label": "Sporting Goods", "value": "Sporting Goods" },
    { "label": "Home & Garden > Linens & Bedding", "value": "Home & Garden > Linens & Bedding" },
    { "label": "Apparel & Accessories > Designer Brands", "value": "Apparel & Accessories > Designer Brands" },
    { "label": "Luggage & Bags > Shopping Totes", "value": "Luggage & Bags > Shopping Totes" },
    { "label": "Home & Garden > Heating, Ventilation & Air Conditioning", "value": "Home & Garden > Heating, Ventilation & Air Conditioning" },
    { "label": "Business & Industrial > Electronics > Communications", "value": "Business & Industrial > Electronics > Communications" },
    { "label": "Sports & Fitness", "value": "Sports & Fitness" },
    { "label": "Furniture > Ottomans", "value": "Furniture > Ottomans" },
    { "label": "Toys & Games > Games", "value": "Toys & Games > Games" },
    { "label": "Luggage & Bags > Handbag & Wallet Accessories", "value": "Luggage & Bags > Handbag & Wallet Accessories" },
    { "label": "Home & Garden > Automotive Accessories", "value": "Home & Garden > Automotive Accessories" },
    { "label": "Food, Beverages & Tobacco > Tobacco Products", "value": "Food, Beverages & Tobacco > Tobacco Products" },
    { "label": "Arts & Entertainment > Photography", "value": "Arts & Entertainment > Photography" },
    { "label": "Apparel & Accessories > Clothing Accessories", "value": "Apparel & Accessories > Clothing Accessories" },
    { "label": "Home & Garden > Decor", "value": "Home & Garden > Decor" },
    { "label": "Luggage & Bags > Handbags, Wallets & Cases", "value": "Luggage & Bags > Handbags, Wallets & Cases" },
    { "label": "Baby & Toddler > Swaddling & Receiving Blankets", "value": "Baby & Toddler > Swaddling & Receiving Blankets" },
    { "label": "Business & Industrial > Printing", "value": "Business & Industrial > Printing" },
    { "label": "Toys & Games > Puzzles", "value": "Toys & Games > Puzzles" },
    { "label": "Sporting Goods > Fishing", "value": "Sporting Goods > Fishing" },
    { "label": "Home & Garden > Furniture", "value": "Home & Garden > Furniture" },
    { "label": "Options-Test-Keep", "value": "Options-Test-Keep" },
    { "label": "Electronics", "value": "Electronics" },
    { "label": "Baby & Toddler > Baby Clothes & Accessories", "value": "Baby & Toddler > Baby Clothes & Accessories" },
    { "label": "Electronics > Video Game Consoles", "value": "Electronics > Video Game Consoles" },
    { "label": "Media > DVDs & Videos", "value": "Media > DVDs & Videos" },
    { "label": "Arts & Entertainment", "value": "Arts & Entertainment" },
    { "label": "Business & Industrial", "value": "Business & Industrial" },
    { "label": "Home & Garden > Home Furnishings", "value": "Home & Garden > Home Furnishings" },
    { "label": "Home & Garden > Flood, Fire & Gas Safety", "value": "Home & Garden > Flood, Fire & Gas Safety" },
    { "label": "Beauty & Personal Care", "value": "Beauty & Personal Care" },
    { "label": "Home & Garden > Emergency Preparedness", "value": "Home & Garden > Emergency Preparedness" },
    { "label": "Automotive > Exterior Accessories > Emblems", "value": "Automotive > Exterior Accessories > Emblems" },
    { "label": "Business & Industrial > Signage", "value": "Business & Industrial > Signage" },
    { "label": "Home & Garden > Bedding", "value": "Home & Garden > Bedding" },
    { "label": "Furniture > Sofas", "value": "Furniture > Sofas" },
    { "label": "Electronics > Mobile Phones & Accessories", "value": "Electronics > Mobile Phones & Accessories" },
    { "label": "Business & Industrial > Industrial Storage", "value": "Business & Industrial > Industrial Storage" },
    { "label": "Apparel & Accessories > Total Merch", "value": "Apparel & Accessories > Total Merch" },
    { "label": "Apparel & Accessories > Clothing, Apparel & Accessories > Clothing Accessories, Apparel & Accessories > Jewelry", "value": "Apparel & Accessories > Clothing, Apparel & Accessories > Clothing Accessories, Apparel & Accessories > Jewelry" },
    { "label": "Sporting Goods > Outdoor Recreation", "value": "Sporting Goods > Outdoor Recreation" },
    { "label": "Beauty & Personal Care > Nail Care", "value": "Beauty & Personal Care > Nail Care" },
    { "label": "Business & Industrial > Heavy Machinery", "value": "Business & Industrial > Heavy Machinery" },
    { "label": "Apparel & Accessories > Wigs", "value": "Apparel & Accessories > Wigs" },
    { "label": "Furniture > Furniture", "value": "Furniture > Furniture" },
    { "label": "Vehicles & Parts > Vehicles", "value": "Vehicles & Parts > Vehicles" },
    { "label": "Baby & Toddler > Nursery Decor & Accessories", "value": "Baby & Toddler > Nursery Decor & Accessories" },
    { "label": "Home & Garden > Aroma Diffusers", "value": "Home & Garden > Aroma Diffusers" },
    { "label": "Home & Garden > Plants", "value": "Home & Garden > Plants" },
    { "label": "Cameras & Optics > Optics", "value": "Cameras & Optics > Optics" },
    { "label": "Brand Passion", "value": "Brand Passion" },
    { "label": "Furniture > Wood Stoves", "value": "Furniture > Wood Stoves" },
    { "label": "Furniture > Furniture Sets", "value": "Furniture > Furniture Sets" },
    { "label": "Software > Computer Software", "value": "Software > Computer Software" },
    { "label": "Furniture > Sofa Accessories", "value": "Furniture > Sofa Accessories" },
    { "label": "Hardware", "value": "Hardware" },
    { "label": "Electronics > Drones & Accessories", "value": "Electronics > Drones & Accessories" },
    { "label": "Electronics > Video", "value": "Electronics > Video" },
    { "label": "Animals & Pet Supplies > Live Animals", "value": "Animals & Pet Supplies > Live Animals" },
    { "label": "Health & Beauty > Makeup", "value": "Health & Beauty > Makeup" },
    { "label": "Apparel & Accessories > Costumes & Accessories", "value": "Apparel & Accessories > Costumes & Accessories" },
    { "label": "Animals & Pet Supplies > Pet Supplies", "value": "Animals & Pet Supplies > Pet Supplies" },
    { "label": "Home & Garden > Fireplaces", "value": "Home & Garden > Fireplaces" },
    { "label": "Electronics > Circuit Boards & Components", "value": "Electronics > Circuit Boards & Components" },
    { "label": "Furniture > Office Furniture", "value": "Furniture > Office Furniture" },
    { "label": "Home & Garden > Pool & Spa", "value": "Home & Garden > Pool & Spa" },
    { "label": "Baby & Toddler > Nursing & Feeding", "value": "Baby & Toddler > Nursing & Feeding" },
    { "label": "Business & Industrial > Education & Training", "value": "Business & Industrial > Education & Training" },
    { "label": "General Store", "value": "General Store" },
    { "label": "Baby & Toddler > Nursery Furniture & Decor", "value": "Baby & Toddler > Nursery Furniture & Decor" },
    { "label": "Business & Industrial > Law Enforcement", "value": "Business & Industrial > Law Enforcement" },
    { "label": "Electronics > Computers", "value": "Electronics > Computers" },
    { "label": "Home & Garden > Nordic Living", "value": "Home & Garden > Nordic Living" },
    { "label": "Furniture > Chairs", "value": "Furniture > Chairs" },
    { "label": "Baby & Toddler > Diapering", "value": "Baby & Toddler > Diapering" },
    { "label": "Furniture > Beds & Accessories", "value": "Furniture > Beds & Accessories" },
    { "label": "Electronics > GPS Navigation Systems", "value": "Electronics > GPS Navigation Systems" },
    { "label": "Business & Industrial > Retail", "value": "Business & Industrial > Retail" },
    { "label": "Electronics > Electronics Accessories", "value": "Electronics > Electronics Accessories" },
    { "label": "Apparel & Accessories > Clothing, Apparel & Accessories > Jewelry", "value": "Apparel & Accessories > Clothing, Apparel & Accessories > Jewelry" },
    { "label": "Apparel & Accessories > Shoe Accessories", "value": "Apparel & Accessories > Shoe Accessories" },
    { "label": "Electronics > Audio", "value": "Electronics > Audio" },
    { "label": "Baby & Toddler > Baby & Toddler Furniture", "value": "Baby & Toddler > Baby & Toddler Furniture" },
    { "label": "Luggage & Bags > Suitcases", "value": "Luggage & Bags > Suitcases" },
    { "label": "Home & Garden > Candles", "value": "Home & Garden > Candles" },
    { "label": "Baby & Toddler > Clothing", "value": "Baby & Toddler > Clothing" },
    { "label": "Photography", "value": "Photography" },
    { "label": "Business & Industrial > Work Safety Protective Gear", "value": "Business & Industrial > Work Safety Protective Gear" },
    { "label": "Home & Garden > Fireplace & Wood Stove Accessories", "value": "Home & Garden > Fireplace & Wood Stove Accessories" },
    { "label": "Health & Beauty > Medical", "value": "Health & Beauty > Medical" },
    { "label": "Business & Industrial > Consulting", "value": "Business & Industrial > Consulting" },
    { "label": "Home & Garden > Cabinets & Storage", "value": "Home & Garden > Cabinets & Storage" },
    { "label": "Arts & Entertainment > Hobbies & Creative Arts", "value": "Arts & Entertainment > Hobbies & Creative Arts" },
    { "label": "Apparel & Accessories > Jewelry", "value": "Apparel & Accessories > Jewelry" },
    { "label": "Baby & Toddler > Baby Health", "value": "Baby & Toddler > Baby Health" },
    { "label": "Toys & Games > Toys", "value": "Toys & Games > Toys" },
    { "label": "Baby & Toddler > Baby Clothing & Accessories", "value": "Baby & Toddler > Baby Clothing & Accessories" },
    { "label": "Electronics > Print, Copy, Scan & Fax", "value": "Electronics > Print, Copy, Scan & Fax" },
    { "label": "Gifts & Specialty", "value": "Gifts & Specialty" },
    { "label": "Baby & Toddler > Baby Gift Sets", "value": "Baby & Toddler > Baby Gift Sets" },
    { "label": "Business & Industrial > Medical", "value": "Business & Industrial > Medical" },
    { "label": "Religious & Ceremonial > Memorial Ceremony Supplies", "value": "Religious & Ceremonial > Memorial Ceremony Supplies" },
    { "label": "Home & Garden > Home Warehouse", "value": "Home & Garden > Home Warehouse" },
    { "label": "Home & Garden > Kitchen & Dining", "value": "Home & Garden > Kitchen & Dining" },
    { "label": "Electronics > Video Game Console Accessories", "value": "Electronics > Video Game Console Accessories" },
    { "label": "Software > Digital Goods & Currency", "value": "Software > Digital Goods & Currency" },
    { "label": "Home & Garden > Parasols & Rain Umbrellas", "value": "Home & Garden > Parasols & Rain Umbrellas" },
    { "label": "Baby & Toddler > Baby Clothes", "value": "Baby & Toddler > Baby Clothes" },
    { "label": "Baby & Toddler > Baby Transport", "value": "Baby & Toddler > Baby Transport" },
    { "label": "Luggage & Bags > Diaper Bags", "value": "Luggage & Bags > Diaper Bags" },
    { "label": "Animals & Pet Supplies", "value": "Animals & Pet Supplies" },
    { "label": "Home & Garden > Smoking Accessories", "value": "Home & Garden > Smoking Accessories" },
    { "label": "Electronics > Networking", "value": "Electronics > Networking" },
    { "label": "Hardware > Building Materials", "value": "Hardware > Building Materials" },
    { "label": "General Center", "value": "General Center" },
    { "label": "Home & Garden > Garage Accessories", "value": "Home & Garden > Garage Accessories" },
    { "label": "Home & Garden > Business & Home Security", "value": "Home & Garden > Business & Home Security" },
    { "label": "Hardware > Locks & Keys", "value": "Hardware > Locks & Keys" },
    { "label": "Animals & Pet Supplies > Pet Supplies", "value": "Animals & Pet Supplies > Pet Supplies" },
    { "label": "Home & Garden > Bathroom Accessories", "value": "Home & Garden > Bathroom Accessories" },
    { "label": "Sporting Goods > Golf", "value": "Sporting Goods > Golf" },
    { "label": "E-commerce store", "value": "E-commerce store" },
    { "label": "Health & Beauty > Hairdressing & Cosmetology", "value": "Health & Beauty > Hairdressing & Cosmetology" },
    { "label": "Office Supplies > Office Instruments", "value": "Office Supplies > Office Instruments" },
    { "label": "Home & Garden > Wood Stoves", "value": "Home & Garden > Wood Stoves" },
    { "label": "Health & Beauty > Nursing & Feeding", "value": "Health & Beauty > Nursing & Feeding" },
    { "label": "Health & Beauty > Personal Care", "value": "Health & Beauty > Personal Care" },
    { "label": "Home & Garden > Lawn & Garden", "value": "Home & Garden > Lawn & Garden" },
    { "label": "Electronics > Communications", "value": "Electronics > Communications" },
    { "label": "Art & Entertainment", "value": "Art & Entertainment" },
    { "label": "Digital Goods & Currency", "value": "Digital Goods & Currency" },
    { "label": "Home & Garden > Home Management Solutions", "value": "Home & Garden > Home Management Solutions" },
    { "label": "Business & Industrial > Hotel & Hospitality", "value": "Business & Industrial > Hotel & Hospitality" },
    { "label": "Business & Industrial > Science & Laboratory", "value": "Business & Industrial > Science & Laboratory" },
    { "label": "Apparel & Accessories > Nail Accessories", "value": "Apparel & Accessories > Nail Accessories" },
    { "label": "Lumber & Woodworking", "value": "Lumber & Woodworking" },
    { "label": "Home & Garden > Cleaning Supplies", "value": "Home & Garden > Cleaning Supplies" },
    { "label": "Business & Industrial > Construction", "value": "Business & Industrial > Construction" },
    { "label": "Beauty", "value": "Beauty" },
    { "label": "Business & Industrial > Piercing & Tattooing", "value": "Business & Industrial > Piercing & Tattooing" },
    { "label": "Furniture", "value": "Furniture" },
    { "label": "Apparel & Accessories > Clothing", "value": "Apparel & Accessories > Clothing" },
    { "label": "Office Supplies > Shipping Supplies", "value": "Office Supplies > Shipping Supplies" },
    { "label": "Gifts & Occasions", "value": "Gifts & Occasions" },
    { "label": "Apparel & Accessories > Sewing Supplies", "value": "Apparel & Accessories > Sewing Supplies" },
    { "label": "Apparel & Accessories > Custom Clothing", "value": "Apparel & Accessories > Custom Clothing" },
    { "label": "Media", "value": "Media" },
    { "label": "Religious & Ceremonial > Religious Items", "value": "Religious & Ceremonial > Religious Items" },
    { "label": "Luggage & Bags > Beach Bags", "value": "Luggage & Bags > Beach Bags" },
    { "label": "Food, Beverages & Tobacco > Beverages", "value": "Food, Beverages & Tobacco > Beverages" },
    { "label": "Home & Garden > Lighting", "value": "Home & Garden > Lighting" },
    { "label": "Apparel & Accessories > Clothing and Accessories", "value": "Apparel & Accessories > Clothing and Accessories" },
    { "label": "Health & Beauty > Health Care", "value": "Health & Beauty > Health Care" },
    { "label": "Office Supplies", "value": "Office Supplies" },
    { "label": "Media > Product Manuals", "value": "Media > Product Manuals" },
    { "label": "Business & Industrial > Food Service", "value": "Business & Industrial > Food Service" },
    { "label": "Cameras & Optics > Video", "value": "Cameras & Optics > Video" },
    { "label": "Baby & Toddler > Baby Feeding", "value": "Baby & Toddler > Baby Feeding" },
    { "label": "Home & Garden > Window Treatments", "value": "Home & Garden > Window Treatments" },
    { "label": "Furniture > Cabinets & Storage", "value": "Furniture > Cabinets & Storage" },
    { "label": "Baby & Toddler > Baby Bathing", "value": "Baby & Toddler > Baby Bathing" },
    { "label": "Home & Garden > Plumbing", "value": "Home & Garden > Plumbing" },
    { "label": "Electronics > Solar Products", "value": "Electronics > Solar Products" },
    { "label": "Business & Industrial > Manufacturing", "value": "Business & Industrial > Manufacturing" },
    { "label": "Office Supplies > Presentation Supplies", "value": "Office Supplies > Presentation Supplies" },
    { "label": "Office Supplies > Book Accessories", "value": "Office Supplies > Book Accessories" },
    { "label": "Media > Music & Sound Recordings", "value": "Media > Music & Sound Recordings" },
    { "label": "Home & Garden > Curtains", "value": "Home & Garden > Curtains" },
    { "label": "Business & Industrial > Finance & Insurance", "value": "Business & Industrial > Finance & Insurance" },
    { "label": "Luggage & Bags > Backpacks", "value": "Luggage & Bags > Backpacks" },
    { "label": "Home & Garden > Window Treatments > Blinds", "value": "Home & Garden > Window Treatments > Blinds" },
    { "label": "Electronics > Components", "value": "Electronics > Components" },
    { "label": "Cameras & Optics", "value": "Cameras & Optics" },
    { "label": "Furniture > Chair Accessories", "value": "Furniture > Chair Accessories" },
    { "label": "Electronics > Cameras & Optics > Photography", "value": "Electronics > Cameras & Optics > Photography" },
    { "label": "Health & Beauty > Jewelry Cleaning & Care", "value": "Health & Beauty > Jewelry Cleaning & Care" },
    { "label": "Apparel & Accessories > Shoes", "value": "Apparel & Accessories > Shoes" },
    { "label": "Health & Beauty > Cosmetics", "value": "Health & Beauty > Cosmetics" },
    { "label": "Health & Beauty > Smoking Accessories", "value": "Health & Beauty > Smoking Accessories" },
    { "label": "Hardware > Fencing & Barriers", "value": "Hardware > Fencing & Barriers" },
    { "label": "Home & Garden", "value": "Home & Garden" },
    { "label": "Antiques & Collectibles", "value": "Antiques & Collectibles" },
    { "label": "Apparel & Accessories > Phone Cases", "value": "Apparel & Accessories > Phone Cases" },
    { "label": "Home & Garden > Household Supplies", "value": "Home & Garden > Household Supplies" },
    { "label": "E-commerce", "value": "E-commerce" },
    { "label": "Baby & Toddler > Nursery Decor & Feeding Accessories", "value": "Baby & Toddler > Nursery Decor & Feeding Accessories" },
    { "label": "Apparel & Accessories > Handbag & Wallet Accessories", "value": "Apparel & Accessories > Handbag & Wallet Accessories" },
    { "label": "Apparel & Accessories", "value": "Apparel & Accessories" },
    { "label": "Apparel & Accessories > Fabric", "value": "Apparel & Accessories > Fabric" },
    { "label": "Sporting Goods > Athletics", "value": "Sporting Goods > Athletics" },
    { "label": "Business & Industrial > Energy", "value": "Business & Industrial > Energy" },
    { "label": "Sporting Goods > Cycling Accessories", "value": "Sporting Goods > Cycling Accessories" },
    { "label": "Baby & Toddler > Baby Toys & Activity Equipment", "value": "Baby & Toddler > Baby Toys & Activity Equipment" },
    { "label": "Gifts & Special Occasions", "value": "Gifts & Special Occasions" },
    { "label": "Electronics > GPS Tracking Devices", "value": "Electronics > GPS Tracking Devices" },
    { "label": "Mature > Weapons", "value": "Mature > Weapons" },
    { "label": "Electronics > Gadgets", "value": "Electronics > Gadgets" },
    { "label": "Mature > Erotic", "value": "Mature > Erotic" },
    { "label": "Business & Industrial > Security", "value": "Business & Industrial > Security" },
    { "label": "Real Estate Services", "value": "Real Estate Services" },
    { "label": "Travel & Tourism", "value": "Travel & Tourism" },
    { "label": "Business & Industrial > Agriculture", "value": "Business & Industrial > Agriculture" },
    { "label": "E-commerce Store", "value": "E-commerce Store" },
    { "label": "Media > Books", "value": "Media > Books" },
    { "label": "Apparel & Accessories > Beachwear", "value": "Apparel & Accessories > Beachwear" },
    { "label": "Toys & Games", "value": "Toys & Games" },
    { "label": "Home & Garden > Doors", "value": "Home & Garden > Doors" },
    { "label": "Mature", "value": "Mature" },
    { "label": "Arts & Entertainment > Party & Celebration", "value": "Arts & Entertainment > Party & Celebration" },
    { "label": "Vehicles & Parts", "value": "Vehicles & Parts" },
    { "label": "Office Supplies > Calculators", "value": "Office Supplies > Calculators" },
    { "label": "Superior Goods", "value": "Superior Goods" },
    { "label": "Business & Industrial > Hairdressing & Cosmetology", "value": "Business & Industrial > Hairdressing & Cosmetology" },
    { "label": "Electronics > Wearable Technology", "value": "Electronics > Wearable Technology" },
    { "label": "Home & Garden > Saunas", "value": "Home & Garden > Saunas" },
    { "label": "Cameras & Optics > Photography", "value": "Cameras & Optics > Photography" },
    { "label": "Apparel & Accessories > Custom Designs", "value": "Apparel & Accessories > Custom Designs" },
    { "label": "Baby & Toddler > Baby Clothing", "value": "Baby & Toddler > Baby Clothing" },
    { "label": "Furniture > Tables", "value": "Furniture > Tables" },
    { "label": "Home & Garden > Storage & Organization", "value": "Home & Garden > Storage & Organization" },
    { "label": "Baby & Toddler > Baby & Toddler Products", "value": "Baby & Toddler > Baby & Toddler Products" },
    { "label": "Motivational Products", "value": "Motivational Products" },
    { "label": "Home & Garden > Flooring", "value": "Home & Garden > Flooring" },
    { "label": "Apparel & Accessories > Eyewear", "value": "Apparel & Accessories > Eyewear" },
    { "label": "Weapons", "value": "Weapons" },
    { "label": "Business & Industrial > Automation Control Components", "value": "Business & Industrial > Automation Control Components" },
    { "label": "Health & Beauty > Fragrances", "value": "Health & Beauty > Fragrances" },
    { "label": "Apparel & Accessories > Outdoor Clothing", "value": "Apparel & Accessories > Outdoor Clothing" },
    { "label": "Baby & Toddler > Baby & Toddler Clothing", "value": "Baby & Toddler > Baby & Toddler Clothing" },
    { "label": "Business & Industrial > Advertising & Marketing", "value": "Business & Industrial > Advertising & Marketing" },
    { "label": "Baby & Toddler", "value": "Baby & Toddler" },
    { "label": "Home & Garden > Household Appliance Accessories", "value": "Home & Garden > Household Appliance Accessories" },
    { "label": "Furniture > Shelving", "value": "Furniture > Shelving" },
    { "label": "Media > Sheet Music", "value": "Media > Sheet Music" },
    { "label": "Office Supplies > Filing & Organization", "value": "Office Supplies > Filing & Organization" },
    { "label": "Furniture > Outdoor Furniture", "value": "Furniture > Outdoor Furniture" },
    { "label": "Tasks & Services", "value": "Tasks & Services" },
    { "label": "Apparel & Accessories > Hair Extensions", "value": "Apparel & Accessories > Hair Extensions" },
    { "label": "Religious & Ceremonial", "value": "Religious & Ceremonial" },
    { "label": "Sports & Outdoors > Hunting & Shooting > Tactical Gear & Accessories", "value": "Sports & Outdoors > Hunting & Shooting > Tactical Gear & Accessories" },
    { "label": "General Dev", "value": "General Dev" },
    { "label": "Unidentified", "value": "Unidentified" },
    { "label": "Luggage & Bags", "value": "Luggage & Bags" },
    { "label": "Hardware > Power & Electrical Supplies", "value": "Hardware > Power & Electrical Supplies" },
    { "label": "Apparel & Accessories > Sunglasses", "value": "Apparel & Accessories > Sunglasses" },
    { "label": "Gifts & Flowers", "value": "Gifts & Flowers" },
    { "label": "Hardware > Heating, Ventilation & Air Conditioning", "value": "Hardware > Heating, Ventilation & Air Conditioning" },
    { "label": "Hardware > Tools", "value": "Hardware > Tools" },
    { "label": "Office Supplies > General Office Supplies", "value": "Office Supplies > General Office Supplies" },
    { "label": "Apparel & Accessories > Watches", "value": "Apparel & Accessories > Watches" },
    { "label": "Apparel & Accessories > Handmade Items", "value": "Apparel & Accessories > Handmade Items" },
    { "label": "Toys & Games > Outdoor Play Equipment", "value": "Toys & Games > Outdoor Play Equipment" },
    { "label": "Jewelry", "value": "Jewelry" },
    { "label": "Food, Beverages & Tobacco", "value": "Food, Beverages & Tobacco" },
    { "label": "Food, Beverages & Tobacco > Food Items", "value": "Food, Beverages & Tobacco > Food Items" },
    { "label": "e-commerce", "value": "e-commerce" },
    { "label": "Arts & Entertainment > Event Tickets", "value": "Arts & Entertainment > Event Tickets" },
    { "label": "Health & Beauty > Dentistry", "value": "Health & Beauty > Dentistry" },
    { "label": "Sporting Goods > Exercise & Fitness", "value": "Sporting Goods > Exercise & Fitness" },
    { "label": "Outdoor Recreation", "value": "Outdoor Recreation" },
    { "label": "Luggage & Bags > Luggage Accessories", "value": "Luggage & Bags > Luggage Accessories" },
    { "label": "Electronics > Mobile Phones", "value": "Electronics > Mobile Phones" },
    { "label": "Home & Garden > Household Appliances", "value": "Home & Garden > Household Appliances" }
]

export const reviewList = [
    { value: "True", label: "True" },
    { value: "False", label: "False" },

];

export const planList = [
    {"label": "In trial", "value": "in-trial"},
    {"label": "Paid at least once", "value": "paid-at-least-once"},
    {"label": "Activated within 3 months", "value": "activated-within-3-months"},
    {"label": "Activated before 3 months", "value": "activated-before-3-months"},
    {"label": "Not activated yet", "value": "not-activated-yet"},

]

export const appBlockList = [
    {"label": "Active on main", "value": "active-on-main"},
    {"label": "Active on unpublished", "value": "active-on-unpublished"},
    {"label": "Not active", "value": "not-active"}
]

export const installList = [
    { value: "True", label: "True" },
    { value: "False", label: "False" },

];

export const passwordProtectedList = [
    { value: "True", label: "True" },
    { value: "False", label: "False" },

];

export const searchInList = [
    { value: "theme_name", label: "Theme Name" },
    { value: "shop_id", label: "Shop Id" },
    { value: "shopify_store_id", label: "Shopify Store ID" },

];